export default [
    // Sudeste
    // Sudeste
    // Sudeste
    {
        name: 'TECMAR Matriz',
        pos: [70.75, 68.35],
        main: true,
        phone: '(11) 3613-5922',
        cep: '06816-005',
        address: [
            'Rua da Congregação, Nº 206',
            'Bairro Gramado',
            'Embu das Artes - SP',
        ],
    },
    {
        name: 'TECMAR Campinas',
        pos: [68.5, 67],
        phone: '(19) 2660-2029',
        cep: '13069-123',
        address: [
            'Av. Maria Herminia Iamarino Ramos, Nº 1411',
            'Bairro Nova Aparecida TIC',
            'Campinas - SP'
        ],
    },
    {
        name: 'TECMAR Santos',
        pos: [72.5, 67.2],
        // phone: '(19) 2660-2029',
        cep: '11570-200',
        address: [
            'Rua Vereador Hugo Scanavacca, Nº 335',
            'Vila Parasi',
            'Santos - SP'
        ],
    },
   
    {
        name: 'TECMAR Pouso Alegre',
        pos: [66.5, 70.25],
        phone: '(35) 3311-1000',
        cep: '37561-110',
        address: [
            'Av. Prefeito Olavo Gomes de Oliveira, 7807/1',
            'Loteamento Parati',
            'Pouso Alegre - MG'
        ],
    },
    {
        name: 'TECMAR Viana',
        pos: [60, 84],
        phone: '(27) 2464-2958',
        cep: '29136-510',
        address: [
            'Rua José Acácio Ferreira, Nº 250',
            'Galpão 3 - Parque Industrial',
            'Viana - ES'
        ],
    },

    // Sul
    // Sul
    // Sul
    {
        name: 'TECMAR Curitiba',
        pos: [75.5, 61.5],
        phone: '(41) 3753-2100',
        cep: '83085-120',
        address: [
            'Rua José Fernandes, Nº 355',
            'Bairro Guatupê',
            'São Jose dos Pinhais - PR'
        ],
    },
    {
        name: 'TECMAR Maringá',
        pos: [72, 55],
        phone: '(44) 3142-2264',
        cep: '87070-780',
        address: [
            'Rua Onofra Aparecida de Magalhães, Nº 833',
            'Jardim Ouro Colar',
            'Maringá - PR'
        ],
    },
    {
        name: 'TECMAR Blumenau',
        pos: [80.5, 61.5],
        phone: '(47) 3334-6565',
        cep: '89065-130',
        address: [
            'Rua: Ari Barroso, Nº 1166',
            'Bairro Itoupavazinha',
            'Blumenau - SC'
        ],
    },
    {
        name: 'TECMAR Joinville',
        pos: [78.5, 62.25],
        phone: '(47) 3429-5812',
        cep: '89233-140',
        address: [
            'Rua Portugal, Nº 851 Galpão 2E',
            'Bairro Santa Catarina',
            'Joinville - SC'
        ],
    },
    {
        name: 'TECMAR Passo Fundo',
        pos: [84, 52],
        phone: '(54) 3310-0110',
        cep: '99042-570',
        address: [
            'Rua Piratini, Nº 80',
            'Bairro Valinhos',
            'Passo Fundo - RS'
        ],
    },
    {
        name: 'TECMAR Cachoeirinha',
        pos: [87.25, 56.75],
        phone: '(51) 3469-5600 ',
        cep: '94935-211',
        address: [
            'Av Frederico Augusto Ritter, Nº 2001',
            'Distrito Industrial Ritter ',
            'Cachoeirinha - RS'
        ],
    },
    
    // Nordeste
    // Nordeste
    // Nordeste
    {
        name: 'TECMAR Salvador',
        pos: [42.5, 88.5],
        phone: '(71) 3295-4900',
        cep: '41297-355',
        address: [
            'Estrada da Iasa, Nº 15',
            'Bairro Pirajá',
            'Salvador - BA'
        ],
    },
    {
        name: 'TECMAR Feira de Santana',
        pos: [41, 87.25],
        phone: '(75) 3614-7100',
        cep: '44079-002',
        address: [
            'Av. Dep.Luis Eduardo Magalhães , S/N, km 103 bl B CIS',
            'Bairro Subae BR 324',
            'Feira de Santana - BA'
        ],
    },
    {
        name: 'TECMAR Aracajú',
        pos: [38.5, 92],
        phone: '(79) 3253-6000',
        cep: '49160-000',
        address: [
            'Av. Boa Viagem, S/N, loteamento Jardim Itacanema',
            'Povoado Sobrado',
            'Nossa Senhora do Socorro - SE'
        ],
    },
    {
        name: 'TECMAR Rio Largo',
        pos: [35.5, 95],
        phone: '(82) 3142-0538',
        cep: '57035-710',
        address: [
            'BR-104, 722',
            'Conj. Napoleão Viana',
            'Rio Largo- AL'
        ],
    },
    {
        name: 'TECMAR Recife',
        pos: [32.25, 97],
        phone: '(81) 3512-5500 ',
        cep: '54503-010',
        address: [
            'Rod. BR 101 Sul, Nº 9200 Km 96',
            'Ponte dos Carvalhos',
            'Cabo de Santo Agostinho - PE'
        ],
    },
    {
        name: 'TECMAR João Pessoa',
        pos: [29, 97.25],
        phone: '(83) 3233-1366 ',
        cep: '58084-000',
        address: [
            'Rua Martinho Lutero, Nº 60',
            'Jardim Veneza',
            'João Pessoa - PB'
        ],
    },
    {
        name: 'TECMAR Natal',
        pos: [25.75, 96.25],
        phone: '(84) 3604-5900 ',
        cep: '59146-480',
        address: [
            'Av. Piloto Pereira Tim, Nº 314',
            'Galpão 2 - Parque de Exposição',
            'Parnamirim - RN'
        ],
    },
    {
        name: 'TECMAR Fortaleza',
        pos: [21.25, 87.75],
        phone: '(85) 3403-4400 ',
        cep: '60873-844',
        address: [
            'Av. 4º Anel Viário, Nº 2700 B',
            'Bairro Pedras',
            'Fortaleza - CE'
        ],
    },

    // Centro-Oeste
    // Centro-Oeste
    // Centro-Oeste
    {
        name: 'TECMAR Goiânia',
        pos: [53, 61.5],
        phone: '(62) 3532-6200 ',
        cep: '74681-215',
        address: [
            'Alameda das Sibipirunas Nº 1530',
            'Sitio do Recreio - Mansões Bernardo Sayão',
            'Goiania - GO'
        ],
    },

    // Norte
    // Norte
    // Norte
    {
        name: 'TECMAR Manaus',
        pos: [22, 28],
        phone: '(92) 3624-3700',
        cep: '69075-110',
        address: [
            'Rua Javari Nº 1155',
            'Bairro Distrito Industrial I',
            'Manaus - AM'
        ],
    },
    {
        name: 'TECMAR Roraima',
        pos: [5, 31],
        phone: '(95) 3015-6248',
        cep: '69315-218',
        address: [
            'Rua Parque Industrial Nº 3023',
            'Bairro Distrito Industrial Governador Aquilino Mota Duarte',
            'Boa Vista - RR'
        ],
    },
]
